/*
 ************
 * BASE URL *
 ************
*/
export const LOGIN_PAGE = 'https://pems.phillipsoutsourcing.net/login';
export const MAIN_MENU = 'https://pems.phillipsoutsourcing.net/home';
export const API_BASE_URL = 'https://api.phillipsoutsourcing.net';
export const DOCUMENT_BASE_URL = 'https://s3.us-east-2.amazonaws.com/api.phillipsoutsourcing.net';
//Company

/*
 **************************
 * CRM Specific Endpoints *
 ***************************
*/
export const INDUSTRIES_URL = '/industries';
export const SEARCH_CITY = '/search-cities';
export const COMPANIES_URL = '/companies';
export const COUNTRIES_URL = '/countries';
export const CRM_USER_URL = '/crm-users';
export const BUSINESS_TYPES_URL = '/business-types';
export const ACTIVE_COMPANY_URL = '/active-companies';
export const ASSIGN_CATEGORY_TO_CUSTOMER = '/assign-customer-category';
export const USERS_COMPANIES_URL = '/get-user-companies';
export const USERS_URL = '/users';
export const GET_USER_AUTHORIZATION = '/get-user-authorization';
export const TOGGLE_CHECK_IN = '/toggle-check-in';
export const GET_EMPLOYEE_TIME = '/get-employee-time';
export const SEARCH_COMPANY_QUERIES = '/search-company-queries';
export const ACTIVITY_TYPES = '/activity-types';
export const CAMPAIGNS = '/campaigns';
export const REMOVE_CAMPAIGN_DOCUMENT = '/remove-campaign-document';
export const REMOVE_DEAL_DOCUMENT = '/remove-deal-document';
export const REMOVE_TASK_DOCUMENT = '/remove-task-document';
export const REMOVE_MEETING_DOCUMENT = '/remove-meeting-document';
export const DEALS = '/deals';
export const COMPANY_CONTACTS = '/company-contacts';
export const ACTIVITIES = '/activities';
export const LEADS = '/leads';
export const NOTES = '/notes';
export const OPPORTUNITIES = '/opportunities';
export const STAGES = '/stages';
export const SOURCES = '/sources';
export const CALLS = '/calls';
export const CRM_TASKS = '/crm-tasks';
export const DEPARTMENTS_URL = '/departments';
export const GET_ACTIVE_CRM_TASKS = '/active-crm-tasks';
export const MEETINGS = '/meetings';
export const COMPANY_TAGS = '/company-tags';
export const COMPANY_BIDS = '/company-bids';
export const LEAD_STATUS = '/lead-statuses';
export const SERVICE_TYPES = '/service-types';
export const MEETING_TYPES = '/meeting-types';
export const SEARCH_CONTACTS = '/search-contacts';
export const SEARCH_USERS = '/search-users';
export const SEARCH_COMPANY_DEPARTMENTS = '/search-company-departments';
export const SEARCH_DEALS = '/search-deals';
export const SEARCH_LEADS = '/search-leads';
export const SEARCH_SOURCES = '/search-sources';
export const SEARCH_TASKS = '/search-tasks';
export const SEARCH_COMPANIES = '/search-companies';
export const SEARCH_COMPANIES_AND_LEADS = '/search-companies-and-leads';
export const CRM_GENERAL_SEARCH = '/crm-general-search';

export const GET_CLOSED_DEALS = '/get-closed-deals';
export const GET_CONVERTED_LEADS = '/get-converted-leads';
export const LEAD_ACROSS_STAGES = '/lead-across-stages';
export const DEAL_ACROSS_STAGES = '/deal-across-stages';
export const BID_STATS = '/bid-statistics';
export const PROFIT_LOSS = '/profit-loss';
export const CRM_STATISTICS = '/crm-statistics';

export const GET_CRM_CLIENTS = '/get-crm-clients';
export const MAKE_CLIENT_REPRESENTATIVE = '/make-client-representative';
export const ASSIGN_LEAD = '/assign-lead';
export const SEARCH_CRM_COMPANIES = '/search-crm-companies';
export const SEARCH_CLIENT_EVENTS = '/search-crm-events';
export const CONTACT_EVENTS = '/contact-events';
export const CLIENT_EVENTS = '/crm-client-events';
export const INDUSTRY_ANALYTICS = '/industry-analytics';
export const CRM_CLIENT_USERS = '/crm-client-users';
export const CRM_ACTIVITY = '/crm-activities';
export const EXPORT_CRM_ACTIVITIES = 'export-crm-activities/';

